// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
        background: $background;
    }

    &.-fullbleed {
        background: $background;
        padding: remify(20);
    }

    .is-fluidboxactive & {
        position: static;
    }
}

.content_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .content-block.-fullbleed > & {
        background: none;
    }

    // featuerdimage variant

    &.-featuredimage {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: remify(auto 200);
    }

    // clean variant

    &.-clean {
        background: none;
        padding: remify(10 0);
    }
}

.content_post {
    & {
        padding: remify(20);
    }

    @media screen and (max-width: $s-break - (1 / 16)) {
        .content_inner.-featuredimage > & {
            padding-top: remify(220);
        }
    }
}

.content_sidebar {
    & {
        background: $background_alt;
        padding: remify(35 20);
        width: 100%;
    }
}

.content_menu-list_container {
    @media screen and (max-width: $s-break - (1 / 16)) {
        .menu-list.-submenu {
            font-size: remify(15, 16);
        }
    }
}

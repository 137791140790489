.sticky-header {
    & {
        width: 100%;
        z-index: 5;
    }

    &:not(.-stickyfix) {
        position: fixed;
    }

    &.-stickyfix {
        position: relative;
        visibility: hidden;
    }

    & .toolbar-block,
    & .header-block {
        width: 100%;
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

.menu-list_container {
    & {
        @extend %clearfix;
    }
}

.menu-list {
    & {
        @extend %clearfix;

        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
    }
}

.menu-list_item {
    & {
        float: left;
        position: relative;
    }

    &.-parent {
        display: flex;
        flex-wrap: wrap;
    }
}

.menu-list_link {
    & {
        display: block;
        flex: 1 1 auto;
        text-decoration: none;
    }
}

.menu-list_toggle {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        margin: 0;
    }

    > .fa {
        transition: transform 0.15s;
    }

    .menu-list_item.is-active > & > .fa {
        transform: rotate(-180deg);
    }
}

// center variant

.menu-list.-center {
    & {
        float: right;
        position: relative;
        right: 50%;
    }

    > .menu-list_item {
        left: 50%;
    }
}

// flex variant

.menu-list.-flex {
    & {
        display: flex;
    }

    > .menu-list_item {
        flex: 1 1 auto;
        width: 0;
    }
}

// vertical variant

.menu-list.-vertical {
    & {
        flex: 0 0 100%;
        text-align: left;
    }

    &.-right {
        text-align: right;
    }

    > .menu-list_item {
        float: none;
    }
}

// tier variant

.menu-list[class*="-tier"] {
    & {
        min-width: 100%;
    }

    // accordion variant

    &.-accordion {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100%;
    }

    .menu-list_item.-parent.is-active > &.-accordion {
        max-height: remify(1000, 16);
        transition: max-height 0.25s;
        visibility: visible;
    }

    // overlay variant

    &.-overlay {
        left: -999em;
        opacity: 0;
        position: absolute;
        transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        top: -999em;
        visibility: hidden;
    }

    .menu-list_item.-parent:hover > &.-overlay,
    .menu-list_item.-parent.is-active > &.-overlay {
        opacity: 1;
        transition: opacity 0.15s;
        visibility: visible;
    }

    // overlay, reverse variant

    &.-overlay.-reverse {
        transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    }

    .menu-list_item.-parent:hover > &.-overlay.-reverse,
    .menu-list_item.-parent.is-active > &.-overlay.-reverse {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

// tier1 variant

.menu-list.-tier1 {
    // overlay variant

    .menu-list_item.-parent:hover > &.-overlay,
    .menu-list_item.-parent.is-active > &.-overlay {
        left: 0;
        top: 100%;
    }

    // overlay, reverse variant

    &.-overlay.-reverse {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &.-overlay.-reverse,
    .menu-list_item.-parent.is-active > &.-overlay.-reverse {
        left: auto;
        right: 0;
    }
}

// tier2 variant

.menu-list.-tier2 {
    // overlay variant

    .menu-list_item.-parent:hover > &.-overlay,
    .menu-list_item.-parent.is-active > &.-overlay {
        left: 100%;
        top: 0;
    }

    // overlay, reverse variant

    &.-overlay.-reverse {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &.-overlay.-reverse,
    .menu-list_item.-parent.is-active > &.-overlay.-reverse {
        left: auto;
        right: 100%;
    }
}

// navigation variant

.menu-list.-navigation {
    & {
        font-family: $heading-font;
        font-size: remify(22, 16);
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: remify(0 0 1 2.94, 22) transparentize($dark, 0.85);
    }

    .menu-list_link {
        color: $primary;
        padding: remify(20 34, 22);
        transition: color 0.15s;
    }

    .menu-list_link:focus,
    .menu-list_link:hover,
    > .menu-list_item.-parent:hover > .menu-list_link,
    > .menu-list_item.-parent.is-active > .menu-list_link {
        color: lighten($primary, 10);
    }

    .menu-list_toggle {
        color: $primary;
        font-size: 1em;
        padding: remify(20 24, 22);
        transition: color 0.15s;
        position: absolute;
        right: 0px;
    }

    .menu-list_toggle:focus,
    .menu-list_toggle:hover,
    .menu-list_item.-parent:hover >.menu-list_toggle,
    .menu-list_item.-parent.is-active >.menu-list_toggle {
        color: lighten($primary, 10);
    }

    .menu-list.-tier1.-accordion,
    .menu-list.-tier1.-overlay {
        background-color: $accent;
        font-size: remify(16, 22);
    }

    .menu-list.-tier1.-accordion:before,
    .menu-list.-tier1.-accordion:after,
    .menu-list.-tier1.-overlay:before,
    .menu-list.-tier1.-overlay:after {
        content: "\0020";
        display: block;
        height: remify(10, 16);
        width: 100%;
    }

    .menu-list.-tier1.-overlay {
        width: remify(300, 16);
    }

    .menu-list.-tier1 .menu-list_link {
        color: $light;
        padding: remify(10 34, 16);
    }

    .menu-list.-tier1 .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_link:hover,
    .menu-list.-tier1.-overlay .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier1.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
        background-color: lighten($accent, 5);
    }

    .menu-list.-tier1 .menu-list_toggle {
        color: $light;
    }

    .menu-list.-tier1 .menu-list_toggle:focus,
    .menu-list.-tier1 .menu-list_toggle:hover,
    .menu-list.-tier1 .menu-list_item.-parent:hover >.menu-list_toggle,
    .menu-list.-tier1 .menu-list_item.-parent.is-active >.menu-list_toggle {
        background-color: lighten($accent, 5);
    }

    .menu-list.-tier2.-accordion,
    .menu-list.-tier2.-overlay {
        background-color: lighten($accent, 10);
    }

    .menu-list.-tier2 .menu-list_link {
        color: $light;
    }

    .menu-list.-tier2.-accordion .menu-list_link:focus,
    .menu-list.-tier2.-accordion .menu-list_link:hover,
    .menu-list.-tier2.-overlay .menu-list_link:focus,
    .menu-list.-tier2.-overlay .menu-list_link:hover,
    .menu-list.-tier2.-overlay .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier2.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
        background-color: lighten(lighten($accent, 10), 5);
    }
}

// breadcrumbs variant

.menu-list.-breadcrumbs {
    & {
        color: $light;
        font-family: $heading-font;
        font-size: remify(30, 16);
        font-weight: 500;
        line-height: remify(29.67741935483871, 30);
        text-align: left;
        text-shadow: remify(0 1 2.94, 30) transparentize($dark, 0.35);
        text-transform: uppercase;
    }

    .menu-list_item {
        padding-right: remify(30, 30);
    }

    .menu-list_item:not(:last-child):after {
        content: ">";
        position: absolute;
        right: remify(8, 30);
        top: 0;
    }

    .menu-list_link {
        color: $light;
        display: inline-block;
        font-weight: inherit;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: darken($light, 20);
    }
}

// submenu variant

.menu-list.-submenu {
    & {
        color: $dark;
        font-family: $heading-font;
        font-size: remify(20, 16);
        font-weight: 700;
        line-height: remify(26, 20);
        text-transform: uppercase;
    }

    .menu-list_link {
        color: $dark;
        padding: remify(0 0 2, 20);
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: lighten($dark, 10);
    }

    .menu-list.-tier1 {
        color: $accent;
        font-family: $body-font;
        font-size: remify(16, 20);
        text-transform: none;
        line-height :remify(20, 16);
    }

    .menu-list.-tier1 .menu-list_link {
        color: $accent;
        padding: remify(3 0 3 14, 16);
        position: relative;
    }

    .menu-list.-tier1 .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_link:hover {
        color: $accent_alt;
    }

    .menu-list.-tier1 .menu-list_link .menu-list_icon {
        color: $dark;
        font-size: remify(10, 16);
        left: remify(-1, 10);
        position: absolute;
        top: remify(7, 10);
    }

    .menu-list.-tier1 .menu-list_link:focus .menu-list_icon,
    .menu-list.-tier1 .menu-list_link:hover .menu-list_icon {
        color: lighten($dark, 10);
    }
}

// meta variant

.menu-list.-meta {
    & {
        color: $foreground_alt;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        text-transform: uppercase;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(20, 14);
    }

    .menu-list_link {
        color: $foreground_alt;
        display: inline-block;
    }

    .menu-list_link:hover {
        color: lighten($foreground_alt, 10);
    }
}

// pagination variant

.menu-list.-pagination {
    & {
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(8, 14);
    }

    .menu-list_link.-current {
        color: $foreground;
        cursor: auto;
    }
}

// footer variant
.menu-list.-footer {
    & {
        font-family: $body-font;
        font-weight: 400;
        margin-top: remify(32, 14);
        text-align: center;
    }

    .menu-item {
        display: inline-block;
        float: none;
        text-align: center;
    }

    .menu-item:not(:last-child):after {
        color: darken($light, 40);
        content: "\007C";
    }

    .menu-list_link {
        color: darken($light, 40);
        display: inline-block;
        font-weight: inherit;
        margin-left: remify(8, 14);
        margin-right: remify(8, 14);
        transition: color 0.15s;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: darken($light, 20);
    }
}

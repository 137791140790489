// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }

    &.-fullbleed {
        background: $background;
    }
}

.hero_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .hero-block.-fullbleed & {
        background: none;
    }
}

// headline variant

.hero-block.-headline {
    &.-fullbleed {
        background: linear-gradient(to bottom, lighten($dark, 20.3922) 0%, lighten($dark, 24.3137) 100%);
    }

    .hero_inner {
        background: linear-gradient(to bottom, lighten($dark, 20.3922) 0%, lighten($dark, 24.3137) 100%);
        padding: remify(15 20);
    }

    .hero_title {
        color: $light;
        font-size: remify(15, 16);
        margin: 0;
        text-shadow: remify(0 0.5 1.47, 15) transparentize($dark, 0.35);
    }

    .hero_logo {
        font-size: remify(15, 16);
    }

    .hero_logo .logo_image {
        margin-top: remify(-6, 15);
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Logo
\* ------------------------------------------------------------------------ */

.logo {
    & {
        display: block;
        text-decoration: none;
    }

}

.logo_image {
    & {
        display: block;
        width: 100%;
    }
}

// partner variant

.logo.-partner {
    & {
        @extend %clearfix;
        color: $light;
        font-family: $heading-font;
        font-size: remify(10, 16);
        font-weight: 700;
        text-transform: uppercase;
        line-height: remify(12, 10);
        width: remify(170, 10);
    }

    .logo_image {
        float: left;
        margin: remify(-5 5 0 0, 10);
        width: remify(50, 10);
    }
}

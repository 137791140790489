// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Toolbar
\* ------------------------------------------------------------------------ */

.toolbar-block {
    & {
        @include layer("toolbar");
    }

    &.-fullbleed {
        & {
            background: linear-gradient(to right, lighten(saturate(adjust-hue($primary, -2.2706), 1.1775), 1.9608) 25%, darken(saturate(adjust-hue($primary, -2.6664), 1.7381), 1.1765) 35%, darken(saturate(adjust-hue($primary, -1.8614), 0.1979), 13.1373) 65%, darken(saturate(adjust-hue($primary, -2.3903), 0.6061), 16.6667) 75%);
            padding: remify(10);
        }
    }
}

.toolbar_inner {
    & {
        background: linear-gradient(to right, lighten(saturate(adjust-hue($primary, -2.2706), 1.1775), 1.9608) 25%, darken(saturate(adjust-hue($primary, -2.6664), 1.7381), 1.1765) 35%, darken(saturate(adjust-hue($primary, -1.8614), 0.1979), 13.1373) 65%, darken(saturate(adjust-hue($primary, -2.3903), 0.6061), 16.6667) 75%);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(10);
    }

    .toolbar-block.-fullbleed > & {
        background: none;
        padding: 0;
    }
}

.toolbar_link {
    & {
        color: $light;
        font-weight: 400;
    }

    &:focus,
    &:hover {
        color: darken($light, 20);
    }
}

.toolbar_title {
    & {
        color: $light;
        font-family: $body-font;
        font-size: remify(22.95, 16);
        font-weight: 300;
        margin: 0;
        text-transform: none;
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget {
    & {
        box-shadow: remify(0 1.5 2.45 0.05, 16) transparentize($dark, 0.55);
        display: block;
        margin: remify(0 0 14, 16);
        position: relative;
        text-align: center;
    }

    &[href] {
        text-decoration: none;
    }

    &[href]:before {
        background: $primary;
        bottom: 0;
        content: "\0020";
        display: block;
        left: 0;
        mix-blend-mode: color;
        opacity: 0;
        position: absolute;
        right: 0;
        transition: opacity 0.15s;
        top: 0;
    }

    &[href]:hover:before {
        opacity: 1;
    }
}

.widget_image {
    & {
        display: block;
        width: 100%;
    }
}

.widget_title {
    & {
        background: transparentize($dark, 0.4);
        color: $light;
        font-size: remify(14.08, 16);
        left: 0;
        line-height: remify(19.7125, 14.08);
        margin-bottom: 0;
        padding: remify(10 15, 14.08);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }
}

// alt variant

.widget.-alt {
    & {
        box-shadow: none;
        margin-bottom: remify(40, 16);
        text-align: left;
    }

    .widget_user-content h6 {
        margin-bottom: 0;
    }

    .widget_user-content p {
        margin-bottom: remify(6, 12);
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }

    &.-fullbleed {
        background: linear-gradient(to right, darken(saturate(adjust-hue($primary, -2.3903), 0.6061), 16.6667) 25%, darken(saturate(adjust-hue($primary, -1.8614), 0.1979), 13.1373) 35%, darken(saturate(adjust-hue($primary, -2.6664), 1.7381), 1.1765) 65%, lighten(saturate(adjust-hue($primary, -2.2706), 1.1775), 1.9608) 75%);
    }
}

.footer_inner {
    & {
        background: linear-gradient(to right, darken(saturate(adjust-hue($primary, -2.3903), 0.6061), 16.6667) 25%, darken(saturate(adjust-hue($primary, -1.8614), 0.1979), 13.1373) 35%, darken(saturate(adjust-hue($primary, -2.6664), 1.7381), 1.1765) 65%, lighten(saturate(adjust-hue($primary, -2.2706), 1.1775), 1.9608) 75%);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(45 55);
    }

    .footer-block.-fullbleed > & {
        background: none;
    }
}

.footer_link {
    & {
        color: $light;
        font-weight: 300;
    }

    &:focus,
    &:hover {
        color: darken($light, 20);
    }
}

.footer_text {
    & {
        color: $light;
        line-height: normal;
        margin-bottom: remify(15, 12);
        text-align: center;
    }
}

.footer_logo {
    &.-partner {
        margin: 0 auto;
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }

    &.-fullbleed {
        & {
            background: linear-gradient(to right, darken($background, 1.1765) remify(170), darken($background, 5.4902) 100%);
        }
    }
}

.header_inner {
    & {
        background: linear-gradient(to right, darken($background, 1.1765) remify(170), darken($background, 5.4902) 100%);
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .header-block.-fullbleed > & {
        background: none;
    }
}

.header_logo {
    & {
        margin: remify(-16 0 0 -16);
        max-width: remify(170);
    }
}

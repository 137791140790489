// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Map
\* ------------------------------------------------------------------------ */

.map {
    & {
        display: block;
        padding: remify(20 0, 16);
        position: relative;
    }
}

.map_image {
    & {
        box-shadow: remify(0 1 2.94 0.06, 16) transparentize($dark, 0.35);
        display: block;
        width: 100%;
    }
}

.map_infobox {
    & {
        overflow: auto;
        margin: remify(0 auto 20);
        padding: remify(6 0 0 42, 16);
        position: relative;
        width: remify(260, 16);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.map_link {
    & {
        color: $foreground;
        font-weight: 400;
    }

    &:focus,
    &:hover {
        color: $accent;
    }
}

.map_icon {
    & {
        font-size: remify(32, 16);
        left: 0;
        position: absolute;
        top: 0;
    }
}

.map_title {
    & {
        font-size: remify(17.98, 16);
        margin-bottom: remify(10, 17.98);
    }
}

.map_text {
    & {
        font-size: remify(14.985, 16);
        line-height: normal;
        margin-bottom: remify(8, 14.985);
    }
}
